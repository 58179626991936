/* Top Bar */
/* Logo Container */
.logo-container {
    position: absolute;
    width: 10%;
    display: flex;
    z-index: 15;
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
    height: 65px; /* Adjust based on your design */
    margin: 20px 0; /* Add some spacing around the logo */
  }
  
  /* Logo Image */
  .logo-image {
    position: absolute;
    max-width: 100%; /* Make sure it doesn’t overflow */
    max-height: 100%; /* Keep it within its container */
    object-fit: contain; /* Ensures proper scaling */
  }
  