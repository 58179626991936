@media only screen and (max-width: 768px) {
    h3 {font-family: Heebo, sans-serif; font-weight: 500; font-size: 8.8vw; padding: 0px; margin: 0px; line-height: 8.8vw;}
    h4 {font-family: Heebo, sans-serif; font-weight: 500; font-size: 6.8vw; padding: 0px; margin: 0px; line-height: 6.8vw;}
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    h3 {font-family: Heebo, sans-serif; font-size: 6.8vw; padding: 0px; margin: 0px;}
    h4 {font-family: Heebo, sans-serif; font-size: 3.8vw; padding: 0px; margin: 0px;}     
}
@media only screen and (min-width: 1024px) {
    h1 {font-family: Heebo, sans-serif; font-size: 2.6vw; padding: 0px; margin: 0px; line-height: 9.8vh; font-weight: 400; letter-spacing: 0.8px;}
    h2 {font-family: Heebo, sans-serif; font-size: 1.6vw; padding: 0px; margin: 0px; font-weight: 500;}
    h3 {font-family: Heebo, sans-serif; font-size: 1.4vw; padding: 0px; margin: 0px; font-weight: 200;}   
    h4 {font-family: Poppins, sans-serif; font-size: 1.2vw; padding: 0px; margin: 0px; font-weight: 500; letter-spacing: -0.2px;}
     p {font-family: Poppins, sans-serif; font-size: 1vw; padding: 0px; margin: 0px; font-weight: 400;}
}

a {text-decoration: none;}
html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    background: 
        radial-gradient(circle, 
            rgba(215, 215, 215, 0.8) 40%, /* Bright center */
            rgba(205, 205, 205, 0.6) 60%, /* Intermediate transparency */
            rgba(197, 197, 197, 0.3) 80%, /* Subtle fade */
            transparent 100% /* Fully transparent edges */
        ), 
        #8bbad2; /* Base background color */
    background-blend-mode: overlay; /* Blend gradient with solid color */
    display: flex; /* Ensures content alignment */
    flex-direction: column;
    overflow: hidden;
}
button {padding-inline: 0px; padding-block: 0px;}