.radial-background {
    z-index: 1;
    position: absolute; /* Position relative to the container */
    bottom: 0; /* Align the gradient at the bottom */
    width: 100%; /* Full width */
    height: 100%; /* Adjust height to control how far the gradient reaches upward */
    pointer-events: none; /* Prevent interaction */
  }
  
  .radial-gradient {
    position: absolute;
    bottom: 0; /* Center the gradient near the bottom edge */
    width: 100%;
    height: 100%;
    background: radial-gradient(
        circle at center bottom, /* Origin at bottom center */
        rgb(238, 239, 240) 0%,  
        rgba(243, 243, 243, 0.75) 40%, 
        rgba(0, 0, 0, 0) 60%    
      );
    pointer-events: none; /* Prevent interaction */
  }
  